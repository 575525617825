
import { defineComponent, ref } from 'vue'
import { handleUpdateUser } from '@/vuetils/useAuth'

export default defineComponent({
  name: 'Auth',

  setup() {
    const password = ref('')

    return {
      password,
      handleUpdateUser,
    }
  },
})
