
import { defineComponent, ref } from 'vue'
import { handleLogin, handleOAuthLogin, handleSignup, handlePasswordReset } from '@/vuetils/useAuth'

export default defineComponent({
  name: 'Auth',

  setup() {
    const email = ref('')
    const password = ref('')

    return {
      email,
      password,
      handleLogin,
      handleOAuthLogin,
      handleSignup,
      handlePasswordReset,
    }
  },
})
