
import { defineComponent, PropType } from 'vue'
import { updateTaskCompletion, deleteTodo } from '@/vuetils/useTodo'
import { allTodos } from '@/vuetils/useTodo'

export default defineComponent({
  name: 'Todo',
  props: {
    todo: {
      type: Object as PropType<Todo>,
      required: true,
    },
  },
  setup(props) {
    // Removes todo from supbase and also from app state
    function clearTodo() {
      deleteTodo(props.todo).then(() => {
        allTodos.value = allTodos.value.filter(todo => todo.id != props.todo.id)
      })
    }

    return { updateTaskCompletion, clearTodo }
  },
})
